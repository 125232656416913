'use client';

import { FC } from 'react';
import Flex from 'antd/lib/flex';

import BodyText from 'components/atoms/BodyText';
import ShowElement from 'components/atoms/ShowElement';
import TagStatus from 'components/molecules/TagStatus';
import Image from 'components/molecules/Image';
import {
  ApplicationCardStyleWrapper,
  ApplicationCardContainer,
  ApplicationListRate,
  AppPriceContainer,
  AppFreeTrialContainer,
  ApplicationLogo,
  ApplicationNameAndPrice,
  ApplicationDescription,
  TagStatusContainer
} from 'components/molecules/ApplicationCard/styles';
import { colors } from 'styles/variables.module';
import { IApplicationCardProps } from 'components/molecules/ApplicationCard/types';
import useTranslations from 'hooks/common/useTranslations';
import { defaultCurrency } from 'common/constants/common';
import priceCounterWithTrialDays from 'utils/helpers/priceCounterWithTrialDays';

const ApplicationCard: FC<IApplicationCardProps> = ({
  name,
  description,
  averageRating,
  reviewsCount,
  price,
  trialDays,
  logoUrl,
  monthlyPrice
}) => {
  const { t } = useTranslations();
  const { freeApp, haveTrialDays, monthlyOrNot } = priceCounterWithTrialDays(
    price,
    monthlyPrice,
    trialDays
  );

  const applicationPrice = monthlyOrNot
    ? `${defaultCurrency}${price}`
    : `${defaultCurrency}${monthlyPrice} ${t('common.mo')}.`;

  return (
    <ApplicationCardStyleWrapper>
      <ApplicationCardContainer>
        <ApplicationLogo>
          <Image width={64} height={64} alt={name} src={logoUrl} border />
        </ApplicationLogo>
        <ApplicationNameAndPrice>
          <div>
            <BodyText
              color={colors.gray_9}
              size="base"
              fontWeight="medium"
              ellipsis
              text={name}
            />
          </div>
          <ShowElement
            isShow={!freeApp}
            show={
              <TagStatusContainer>
                <TagStatus
                  minWidth={42}
                  status="FREE"
                  text={t('common.free')}
                />
              </TagStatusContainer>
            }
          >
            <AppPriceContainer>
              <TagStatus
                minWidth={64}
                status="NOT_FREE"
                text={applicationPrice}
              />
              <ShowElement isShow={haveTrialDays}>
                <AppFreeTrialContainer>
                  {t('common.freeTrialDaysAppCard', {
                    trialDays: trialDays
                  })}
                </AppFreeTrialContainer>
              </ShowElement>
            </AppPriceContainer>
          </ShowElement>
        </ApplicationNameAndPrice>
        <ApplicationDescription>
          <BodyText
            color={colors.gray_8}
            linecalm={2}
            size="small"
            fontWeight="regular"
            text={description}
            ellipsisText
          />
          <Flex justify="space-between" align="center">
            <div>
              <BodyText
                color={colors.gray_9}
                size="small"
                fontWeight="regular"
                ellipsisText
                text={t('common.byUcraft')}
              />
            </div>

            <Flex align="center" gap={8}>
              <Flex align="center" gap={4}>
                <BodyText
                  text={String(averageRating)}
                  size="small"
                  fontWeight="regular"
                  color={colors.gray_9}
                />
                <ApplicationListRate
                  size="small"
                  count={1}
                  value={averageRating}
                  disabled
                />
              </Flex>

              <BodyText
                text={t('common.reviewCount', {
                  count: reviewsCount
                })}
                size="small"
                fontWeight="regular"
                color={colors.gray_8}
              />
            </Flex>
          </Flex>
        </ApplicationDescription>
      </ApplicationCardContainer>
    </ApplicationCardStyleWrapper>
  );
};

export default ApplicationCard;
