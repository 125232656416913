'use client';

import { FC } from 'react';
import Col from 'antd/lib/col';

import { IApplicationsListProps } from 'components/organisms/ApplicationsList/types';
import ApplicationCard from 'components/molecules/ApplicationCard';
import ApplicationCardsSkeleton from 'components/molecules/ApplicationCardsSkeleton';
import Empty from 'components/molecules/Empty';
import {
  ApplicationsListRowStyle,
  ApplicationsListStyleWrapper
} from 'components/organisms/ApplicationsList/styles';
import { SCREEN_SIZES_FOR_CARDS } from 'components/organisms/ApplicationsList/constants';
import { IApplicationsItemsModel } from 'common/globalTypes/interfaces/apiResponseModel';
import LoadingWrapper from 'components/atoms/LoadingWrapper';
import NextLink from 'components/atoms/NextLink';
import ShowElement from 'components/atoms/ShowElement';
import useRouteWithLanguage from 'hooks/common/useRouteWithLanguage';
import useTranslations from 'hooks/common/useTranslations';
import { Routes } from 'common/constants/common';

const ApplicationsList: FC<IApplicationsListProps> = ({
  applications,
  loading
}) => {
  const { t } = useTranslations('common');
  const { pathname } = useRouteWithLanguage();
  const isSearchPage = pathname.includes(Routes.SEARCH.pathname);
  const emptyTextKey = isSearchPage ? 'noSearchResult' : 'notFoundApps';

  return (
    <ApplicationsListStyleWrapper>
      <LoadingWrapper
        loading={Boolean(loading)}
        indicator={<ApplicationCardsSkeleton cardCount={12} />}
      >
        <ShowElement
          isShow={Boolean(applications.length)}
          show={<Empty text={t(emptyTextKey)} />}
        >
          <ApplicationsListRowStyle gutter={[16, 16]}>
            {applications.map((application: IApplicationsItemsModel) => (
              <Col {...SCREEN_SIZES_FOR_CARDS} key={application.id}>
                <NextLink
                  href={`/${application.alias}`}
                  title={application.name}
                >
                  <ApplicationCard
                    id={application.id}
                    name={application.name}
                    description={application.description}
                    logoUrl={application.logoUrl}
                    averageRating={application.averageRating}
                    reviewsCount={application.reviewsCount}
                    monthlyPrice={application.monthlyPrice}
                    price={application.price}
                    trialDays={application.trialDays}
                    developer={application.developer || ''}
                  />
                </NextLink>
              </Col>
            ))}
          </ApplicationsListRowStyle>
        </ShowElement>
      </LoadingWrapper>
    </ApplicationsListStyleWrapper>
  );
};

export default ApplicationsList;
