import { FC } from 'react';

import BodyText from 'components/atoms/BodyText';
import { ISectionTitleProps } from 'components/molecules/SectionTitle/types';
import { colors } from 'styles/variables.module';
import {
  HeadingCategoryStyleWrapper,
  SectionTitleStyleWrapper
} from './styles';

const SectionTitle: FC<ISectionTitleProps> = ({ title, subTitle }) => {
  return (
    <SectionTitleStyleWrapper>
      <HeadingCategoryStyleWrapper level={4} fontWeight="bold" text={title} />
      <BodyText color={colors.gray_8} size="base" text={subTitle} />
    </SectionTitleStyleWrapper>
  );
};

export default SectionTitle;
